import fetchAllCurrencies from '@lyra/core/api/fetchAllCurrencies'
import { CurrencyResponseSchema } from '@lyra/core/api/types/public.get_all_currencies'
import { WEI_DECIMALS } from '@lyra/core/constants/contracts'
import { bigNumberToNumberUNSAFE } from '@lyra/core/utils/bigNumberToNumberUNSAFE'
import { bigNumberToString } from '@lyra/core/utils/bigNumberToString'
import filterNulls from '@lyra/core/utils/filterNulls'
import formatNumber from '@lyra/core/utils/formatNumber'
import { Address } from 'viem'

import erc20Abi from '../abis/erc20Abi'
import {
  socketDepositContractAddresses,
  socketWithdrawContractAddresses,
} from '../constants/bridge'
import { DepositNetwork } from '../constants/chains'
import { lyraClient } from '../constants/client'
import {
  lyraContractAddresses,
  mainnetLyraContractAddresses,
  testnetLyraContractAddresses,
} from '../constants/contracts'
import { isMainnet, isTestnet } from '../constants/env'
import { MarketId, marketsConfig } from '../constants/markets'
import {
  Collateral,
  collateralConfig,
  CollateralId,
  depositTokenConfig,
  DepositTokenId,
  EMPTY_COLLATERALS,
  ETH_ADDRESS,
  PartialToken,
  tokenConfig,
  TokenId,
} from '../constants/tokens'
import { getEmptyTokenBalances } from '../constants/wallet'
import { deepCopy } from './array'
import {
  getActiveDepositTokens,
  getActiveWithdrawTokens,
  getDepositContractAddresses,
} from './bridge'
import { getChainForDepositNetwork } from './chains'
import { coerce } from './types'

export const getDepositTokenAddress = (network: DepositNetwork, token: DepositTokenId): Address => {
  const depositAddresses = getDepositContractAddresses(network, token)
  const address = depositAddresses.tokenAddress
  if (!address) {
    throw new Error(`${token} not supported on ${network}`)
  }
  return address
}

export const getDepositTokenForAddress = (
  network: DepositNetwork,
  tokenAddress: Address
): DepositTokenId => {
  if (tokenAddress.toLowerCase() === ETH_ADDRESS.toLowerCase()) {
    return DepositTokenId.ETH
  }
  const chainId = getChainForDepositNetwork(network).id.toString()
  const ret = Object.entries(socketDepositContractAddresses[chainId]).find(
    ([_0, socketAddresses]) =>
      socketAddresses.NonMintableToken.toLowerCase() === tokenAddress.toLowerCase()
  )
  const tokenId = ret ? coerce(DepositTokenId, ret[0]) : undefined
  if (!tokenId) {
    if (isTestnet) {
      return DepositTokenId.USDC
    }
    throw new Error(`Unsupported token address: ${tokenAddress}`)
  }
  return tokenId
}

export const getLyraTokenForAddress = (tokenAddress: Address): TokenId => {
  const ret = Object.entries(socketWithdrawContractAddresses).find(
    ([_0, addresses]) => tokenAddress.toLowerCase() === addresses.MintableToken.toLowerCase()
  )
  const tokenId = ret ? coerce(TokenId, ret[0]) : undefined
  if (!tokenId) {
    throw new Error(`Unsupported token address: ${tokenAddress}`)
  }
  return tokenId
}

export const getLyraTokenAddress = (token: TokenId): Address => {
  const addresses = socketWithdrawContractAddresses
  const symbol = formatSocketTokenSymbol(token)
  if (!(symbol in addresses)) {
    throw new Error(`${token} not supported on Derive`)
  }
  return addresses[symbol].MintableToken
}

export const getCollateralAddress = (collateral: CollateralId): Address => {
  const addresses = isMainnet ? mainnetLyraContractAddresses : testnetLyraContractAddresses
  if (!(collateral in addresses.collateral)) {
    throw new Error(`${collateral} not supported as collateral`)
  }
  return (addresses as any)['collateral'][collateral]
}

const getDepositTokensForToken = (network: DepositNetwork, token: TokenId): DepositTokenId[] => {
  const supportedDepositTokens = getActiveDepositTokens(network)
  return supportedDepositTokens.filter(
    (depositToken) => depositTokenConfig[depositToken].tokenConfig.tokenId === token
  )
}

const getWithdrawTokensForToken = (network: DepositNetwork, token: TokenId): DepositTokenId[] => {
  const supportedWithdrawTokens = getActiveWithdrawTokens(network)
  return supportedWithdrawTokens.filter(
    (withdrawToken) => depositTokenConfig[withdrawToken].tokenConfig.tokenId === token
  )
}

const getNetworksForToken = (token: TokenId): DepositNetwork[] => {
  return Object.values(DepositNetwork).filter((network) =>
    getActiveDepositTokens(network)
      .map((depositToken) => getTokenForDepositToken(depositToken))
      .includes(token)
  )
}

export const getDefaultNetworkForToken = (token: TokenId): DepositNetwork | undefined => {
  const supportedNetworks = sortNetworks(getNetworksForToken(token))
  return supportedNetworks.length ? supportedNetworks[0] : undefined
}

export const getNetworksForDepositToken = (token: DepositTokenId): DepositNetwork[] => {
  return Object.values(DepositNetwork).filter((network) =>
    getActiveDepositTokens(network).includes(token)
  )
}

export const getDefaultDepositTokenForToken = (token: TokenId): DepositTokenId | undefined => {
  const defaultNetwork = getDefaultNetworkForToken(token)
  if (!defaultNetwork) {
    return
  }
  const supportedDepositTokens = sortDepositTokens(getDepositTokensForToken(defaultNetwork, token))
  return supportedDepositTokens.length ? supportedDepositTokens[0] : undefined
}

export const getDefaultWithdrawTokenForToken = (
  network: DepositNetwork,
  token: TokenId
): DepositTokenId | undefined => {
  const supportedWithdrawTokens = sortDepositTokens(getWithdrawTokensForToken(network, token))
  return supportedWithdrawTokens.length ? supportedWithdrawTokens[0] : undefined
}

// CollateralId -> TokenId
export const getTokenForCollateral = (collateral: CollateralId): TokenId => {
  return collateralConfig[collateral].tokenId
}

export const getCollateralForToken = (token: TokenId): CollateralId => {
  return tokenConfig[token].collateralId
}

// DepositTokenId -> TokenId
export const getTokenForDepositToken = (token: DepositTokenId): TokenId => {
  return depositTokenConfig[token].tokenConfig.tokenId
}

// DepositTokenId -> CollateralId
export const getCollateralForDepositToken = (token: DepositTokenId): CollateralId => {
  return depositTokenConfig[token].tokenConfig.collateralId
}

type FormatBalanceOptions = {
  truncate?: boolean
}

export const formatTokenBalance = (
  amount: bigint | string,
  tokenId: TokenId,
  options?: FormatBalanceOptions
) => {
  const truncate = !!options?.truncate

  const decimals = tokenConfig[tokenId].decimals
  const amountStr = bigNumberToString(amount, decimals)

  return `${formatNumber(+amountStr, {
    precision: !truncate ? Math.pow(10, -decimals) : undefined,
  })} ${formatTokenSymbol(tokenId)}`
}

export const formatDepositTokenBalance = (
  amount: bigint | string,
  tokenId: DepositTokenId,
  options?: FormatBalanceOptions
) => {
  const truncate = !!options?.truncate

  const decimals = depositTokenConfig[tokenId].decimals
  const amountStr = bigNumberToString(amount, decimals)

  return `${formatNumber(+amountStr, {
    precision: !truncate ? Math.pow(10, -decimals) : undefined,
    maxDps: truncate ? WEI_DECIMALS : 6,
  })} ${formatDepositTokenSymbol(tokenId)}`
}

export const formatCollateralBalance = (
  _amount: number | bigint,
  collateralId: CollateralId,
  options?: FormatBalanceOptions
) => {
  const truncate = !!options?.truncate

  // Collateral is always in 18d.p.
  const amount =
    typeof _amount === 'bigint' ? bigNumberToNumberUNSAFE(_amount, WEI_DECIMALS) : _amount
  return `${formatNumber(amount, {
    precision: !truncate ? Math.pow(10, -WEI_DECIMALS) : undefined,
  })} ${formatTokenSymbol(collateralId)}`
}

// Note: we intentionally group token + collateral for consistency
// Users will perceive them as the same thing in Lyra Chain / Protocol
export const formatTokenSymbol = (token: CollateralId | TokenId): string => {
  return (collateralConfig[token as CollateralId] ?? tokenConfig[token as TokenId]).symbol
}

export const formatDepositTokenSymbol = (token: DepositTokenId): string =>
  depositTokenConfig[token].symbol

// Formats the field accessor for Socket addresses
export const formatSocketTokenSymbol = (token: TokenId): string => {
  if (token === TokenId.ETH) {
    return 'WETH'
  }
  return tokenConfig[token].symbol
}

export const formatSocketDepositTokenSymbol = (token: DepositTokenId): string => {
  if (token === DepositTokenId.ETH) {
    return 'WETH'
  }
  return depositTokenConfig[token].symbol
}

export const getIsL2 = (network: DepositNetwork): boolean => {
  switch (network) {
    case DepositNetwork.Arbitrum:
    case DepositNetwork.Optimism:
    case DepositNetwork.Base:
      return true
    case DepositNetwork.Ethereum:
      return false
  }
}

export const getActiveTokens = (): TokenId[] => {
  return filterNulls(
    Object.entries(socketWithdrawContractAddresses)
      .filter(([key, address]) => {
        const tokenId = key === 'WETH' ? TokenId.ETH : coerce(TokenId, key)
        return !!address.MintableToken && tokenId && tokenConfig[tokenId].isActive
      })
      .map(([key]) => (key === 'WETH' ? TokenId.ETH : coerce(TokenId, key)))
  )
}

export const getActiveCollateral = (): CollateralId[] => {
  return Object.entries(lyraContractAddresses.collateral)
    .filter(([key, address]) => !!address && collateralConfig[key as CollateralId].isActive)
    .map(([key]) => key as CollateralId)
}

export const getCollateralForMarket = (market: MarketId): CollateralId | undefined => {
  return marketsConfig[market].collateral
}

export const getTokenDecimals = (token: DepositTokenId | TokenId): number => {
  // WARNING: tokens on source and destination chain may not actually have the same # decimals in practice
  return (depositTokenConfig[token as DepositTokenId] ?? tokenConfig[token as TokenId]).decimals
}

export const getSupportedNetworksForDepositTokens = (tokens: DepositTokenId[]) => {
  return Array.from(new Set(tokens.flatMap((token) => getNetworksForDepositToken(token))))
}

export const getSupportedNetworksForTokens = (tokens: TokenId[]) => {
  return Array.from(new Set(tokens.flatMap((token) => getNetworksForToken(token))))
}

export const sortTokens = (tokens: TokenId[]) => {
  const order = Object.values(TokenId)
  return tokens.sort((a, b) => order.indexOf(a) - order.indexOf(b))
}

export const sortDepositTokens = (tokens: DepositTokenId[]) => {
  const order = Object.values(DepositTokenId)
  return tokens.sort((a, b) => order.indexOf(a) - order.indexOf(b))
}

export const sortCollaterals = (collaterals: CollateralId[]) => {
  const order = Object.values(CollateralId)
  return collaterals.sort((a, b) => order.indexOf(a) - order.indexOf(b))
}

export const sortNetworks = (networks: DepositNetwork[]) => {
  const order = Object.values(DepositNetwork)
  return networks.sort((a, b) => order.indexOf(a) - order.indexOf(b))
}

export const formatTokenName = (token: TokenId | CollateralId): string =>
  (tokenConfig[token as TokenId] ?? collateralConfig[token as CollateralId]).name

export const formatDepositTokenName = (token: DepositTokenId): string =>
  depositTokenConfig[token].name

export const getCollateralBalancesMap = (
  currencies: CurrencyResponseSchema[],
  collateralBalances?: Record<CollateralId, number>
) => {
  return Object.values(CollateralId).reduce(
    (balances, tokenId) => {
      const currency = currencies.find((c) => c.currency === tokenId)
      return {
        ...balances,
        [tokenId]: {
          balance: collateralBalances ? collateralBalances[tokenId] : 0,
          value:
            currency && collateralBalances ? collateralBalances[tokenId] * +currency.spot_price : 0,
        },
      }
    },
    {} as Record<CollateralId, { balance: number; value: number }>
  )
}

type Options = {
  showFullBalance?: boolean
}

export const formatBalance = (
  amount: bigint | number,
  token: PartialToken,
  options?: Options
): string => {
  const showFullBalance = !!options?.showFullBalance

  const amountNum =
    typeof amount === 'bigint' ? bigNumberToNumberUNSAFE(amount, token.decimals) : amount

  return `${showFullBalance ? amountNum : formatNumber(amountNum, { minDps: 1 })} ${token.symbol}`
}

export const formatEthBalance = (amount: bigint): string => {
  const amountNum = bigNumberToNumberUNSAFE(amount, WEI_DECIMALS)
  if (amountNum <= 0.001) {
    return '< 0.001 ETH'
  }
  return `${formatNumber(amountNum, { minDps: 1 })} ETH`
}

const getPriceData = (currency: CurrencyResponseSchema) => {
  const spotPrice = +currency.spot_price
  const spotPrice24hAgo = +currency.spot_price_24h
  const spotPrice24hChange = spotPrice - spotPrice24hAgo
  const spotPrice24hPctChange =
    spotPrice24hAgo !== 0 ? (spotPrice - spotPrice24hAgo) / spotPrice24hAgo : 0

  return {
    spotPrice,
    spotPrice24hAgo,
    spotPrice24hChange,
    spotPrice24hPctChange,
  }
}

export const getCollateralsFromCurrencies = (
  initialCurrencies: CurrencyResponseSchema[]
): Record<CollateralId, Collateral> => {
  return initialCurrencies.reduce((dict, currency) => {
    const priceData = getPriceData(currency)

    const collateralId = coerce(CollateralId, currency.currency)
    if (!collateralId) {
      return dict
    }

    return {
      ...dict,
      [collateralId]: {
        ...collateralConfig[collateralId],
        ...priceData,
      },
    }
  }, deepCopy(EMPTY_COLLATERALS))
}

export const fetchCollaterals = async () => {
  const { result } = await fetchAllCurrencies()
  return getCollateralsFromCurrencies(result)
}

export const fetchtTotalSupplies = async (
  blockNumber?: bigint
): Promise<Record<TokenId, bigint>> => {
  const tokens = getActiveTokens()

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore infinite depth call, typescript wigging out
  const totalSupplies = await lyraClient.multicall({
    contracts: tokens.map((token) => ({
      address: getLyraTokenAddress(token),
      abi: erc20Abi,
      functionName: 'totalSupply',
      blockNumber,
    })),
  })

  return totalSupplies.reduce((totalSupplies, totalSupply, i) => {
    const token = tokens[i]
    if (totalSupply.status === 'failure') {
      console.warn('[multicall] failed to fetch totalSupply for', token)
      return totalSupplies
    }
    return {
      ...totalSupplies,
      [token]: totalSupply.result,
    }
  }, getEmptyTokenBalances())
}

type CollateralTvl = {
  id: CollateralId
  tvl: number
  totalSupply: bigint
}

export const fetchTvl = async (): Promise<{
  tvl: number
  collaterals: Record<CollateralId, CollateralTvl>
}> => {
  const [collaterals, totalSupplies] = await Promise.all([
    fetchCollaterals(),
    fetchtTotalSupplies(),
  ])

  const tvls = Object.values(collaterals).map(({ collateralId, spotPrice }) => {
    const token = getTokenForCollateral(collateralId)
    const totalSupply = totalSupplies[getTokenForCollateral(collateralId)]
    const totalSupplyNum = bigNumberToNumberUNSAFE(totalSupply, getTokenDecimals(token))
    return { id: collateralId, totalSupply, tvl: totalSupplyNum * spotPrice }
  })

  return {
    tvl: tvls.reduce((sum, { tvl }) => sum + tvl, 0),
    collaterals: tvls.reduce(
      (dict, { id, tvl, totalSupply }) => ({
        ...dict,
        [id]: {
          id,
          tvl,
          totalSupply,
        },
      }),
      {} as Record<CollateralId, CollateralTvl>
    ),
  }
}

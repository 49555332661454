import { Address } from 'viem'

export type SocketDepositContractAddresses = {
  [chainId: string]: {
    [tokenId: string]: {
      Vault: Address
      NonMintableToken: Address
      isNewBridge: boolean
      connectors: {
        '957': {
          FAST: Address
        }
      }
    }
  }
}

export type SocketWithdrawContractAddresses = {
  [tokenId: string]: {
    Controller: Address
    MintableToken: Address
    isNewBridge: boolean
    connectors: {
      [chainId: string]: {
        FAST: Address
      }
    }
  }
}

export const TESTNET_CHAIN_ID = '901'
export const MAINNET_CHAIN_ID = '957'

import { GelatoRelay } from '@gelatonetwork/relay-sdk'
import { TaskState } from '@gelatonetwork/relay-sdk/dist/lib/status/types'
import { TxStatus } from '@lyra/core/api/types/channel.subaccount_id.trades'
import { PrivateDepositParamsSchema } from '@lyra/core/api/types/private.deposit'
import { MAX_INT } from '@lyra/core/constants/contracts'
import { SECONDS_IN_DAY, SECONDS_IN_THREE_DAYS } from '@lyra/core/constants/time'
import { Address, Hash } from 'viem'

import { DepositNetwork } from './chains'
import { isMainnet } from './env'
import {
  MAINNET_DEPOSIT_CONTRACT_ADDRESSES,
  MAINNET_WITHDRAW_CONTRACT_ADDRESSES,
} from './socket/bridgeAddresses'
import {
  TESTNET_DEPOSIT_CONTRACT_ADDRESSES,
  TESTNET_WITHDRAW_CONTRACT_ADDRESSES,
} from './socket/bridgeAddresses.testnet'
import { TokenId } from './tokens'
import { YieldStrategyId } from './yield'

export type SocketMessageStatus =
  | 'LYRA_CONFIRMING'
  | 'RECEIVED'
  | 'SEALED'
  | 'PROPOSED'
  | 'ATTESTED'
  | 'VERIFIED'
  | 'EXECUTING'
  | 'EXECUTION_SUCCESS'
  // Reverting
  | 'INBOUND_REVERTING'
  // Failed
  | 'EXECUTION_FAILURE'

// Gelato task status
export type RelayerStatus = TaskState

export type MessageStatus =
  | (
      | 'LYRA_CONFIRMING'
      | 'RECEIVED'
      | 'SEALED'
      | 'PROPOSED'
      | 'ATTESTED'
      | 'VERIFIED'
      | 'EXECUTING'
      | 'EXECUTION_SUCCESS'
      // Reverting
      | 'INBOUND_REVERTING'
      // Failed
      | 'EXECUTION_FAILURE'
    )
  | TaskState

// Union of orderbook status and:
//   queued - bridge has not completed yet
//   rpc-error - a known orderbook RPC error returned by API call
//   error - some other error returned by API call
//   error-unknown - an error with the fetch
export type SubaccountDepositStatus = TxStatus | 'queued' | 'rpc-error' | 'error' | 'error-unknown'

export const SOCKET_MESSAGE_STATUSES = [
  'LYRA_CONFIRMING',
  'RECEIVED',
  'SEALED',
  'PROPOSED',
  'ATTESTED',
  'VERIFIED',
  'EXECUTING',
  'EXECUTION_SUCCESS',
  // Reverting
  'INBOUND_REVERTING',
  // Failed
  'EXECUTION_FAILURE',
]

export const SOCKET_MESSAGE_COMPLETE_STATUSES: MessageStatus[] = [
  'EXECUTION_FAILURE',
  'EXECUTION_SUCCESS',
]

export const GELATO_MESSAGE_FAILED_STATUSES: TaskState[] = [
  TaskState.ExecReverted,
  TaskState.Cancelled,
]

export const GELATO_MESSAGE_COMPLETE_STATUSES: TaskState[] = [
  ...GELATO_MESSAGE_FAILED_STATUSES,
  TaskState.ExecSuccess,
]

// Statuses that indicate the transaction does not require anymore updates, i.e.
// Gelato task or Socket message has failed
// OR Socket message is complete
export const TX_COMPLETE_STATUSES = [
  ...SOCKET_MESSAGE_COMPLETE_STATUSES,
  ...GELATO_MESSAGE_FAILED_STATUSES,
]

// Any status except "queued"
export const SUBACCOUNT_COMPLETE_STATUS: SubaccountDepositStatus[] = [
  'requested',
  'settled',
  'reverted',
  'timeout',
  'rpc-error',
  'error',
  'error-unknown',
]
export const BRIDGE_GAS_SUBMITTED_STATUSES: MessageStatus[] = [
  'EXECUTION_SUCCESS',
  'INBOUND_REVERTING',
  TaskState.ExecReverted,
]
export const BRIDGE_FAILED_STATUSES: MessageStatus[] = ['EXECUTION_FAILURE']

type InboundStatus = 'NOT_FOUND' | 'NOT_TRIED' | 'REVERTING' | 'EXECUTING' | 'SUCCESS'

/**
 * Derived client-side status
 * Reverted - Socket transaction reverted or subaccount deposit failed/reverted (fees have been paid)
 * Cancelled - Gelato task has failed, no fees paid
 */
export type BridgeTransactionStatus = 'pending' | 'completed' | 'reverted' | 'cancelled'

export type SocketStatusResponse = {
  status: InboundStatus
  result: {
    status: SocketMessageStatus
    messageId: string
    packetId: string
    from: {
      srcChainSlug: string
      srcPlug: string
    }
    to: {
      dstChainSlug: string
      destPlug: string
    }
    outboundTx: string
    packedMessage: string
    inboundTx: string
    executionDetails: {
      inboundStatus: string // Replace with the actual type for inbound status
      isExecuted: boolean
      executor: string
      executionTxHash: string
    }
  }[]
}

export type SocketMessageIdStatusResponse = {
  status: InboundStatus
  result: {
    status: SocketMessageStatus
    messageId: string
    packetId: string
    from: {
      srcChainSlug: string
      srcPlug: string
    }
    to: {
      dstChainSlug: string
      destPlug: string
    }
    outboundTx: string
    packedMessage: string
    inboundTx: string
    executionDetails: {
      inboundStatus: string // Replace with the actual type for inbound status
      isExecuted: boolean
      executor: string
      executionTxHash: string
    }
  }
}

export type BridgeTransactionType =
  | 'deposit'
  | 'withdraw'
  | 'predeposit-yield'
  | 'prewithdraw-yield'
  | 'deposit-yield'
  | 'withdraw-yield'

export const yieldTransactionTypes: BridgeTransactionType[] = [
  'predeposit-yield',
  'prewithdraw-yield',
  'deposit-yield',
  'withdraw-yield',
]

export type DepositQuoteGasOptions = {
  srcChainGasEstimate?: number
  maxFeePerGas?: number
  maxPriorityFeePerGas?: number
}

export type SubaccountDepositFields = {
  depositParams: PrivateDepositParamsSchema
  status: SubaccountDepositStatus
  transactionId?: string | null
}

// Firestore document
export type BridgeTransactionDocument = {
  address: Address
  sender: Address
  receiver: Address
  tokenAddress: Address
  tokenSymbol: string
  outboundTxHash: Address | null
  srcChainId: number
  dstChainId: number
  amount: string
  fees?: string
  blockTimestamp: string
  timestamp: number
  transactionType: BridgeTransactionType
  status: MessageStatus
  inboundTxHash: Address | null
  relayerTaskId?: string | null
  relayerTaskStatus?: TaskState | null
  isNative?: boolean
  isSponsored?: boolean
  isZap?: boolean
  // subaccountDeposit is DEPRECATED, keeping the field for old db entries
  subaccountDeposit?: SubaccountDepositFields | null
}

export type BridgeToLyraStatus = {
  status: SocketMessageStatus
  srcChainId: number
  srcTxHash: `0x${string}` | null
  lyraTxHash: `0x${string}` | null
  gelato?: {
    status: RelayerStatus
    taskId: string
  } | null
  // subaccountDeposit is DEPRECATED, keeping the field for old db entries
  subaccountDeposit?: {
    params: PrivateDepositParamsSchema
    status: SubaccountDepositStatus
    error?: any
    transactionId?: string
  } | null
  isNative?: boolean
  isSponsored?: boolean
}

export type BridgeFromLyraStatus = {
  status: SocketMessageStatus
  dstChainId: number
  dstTxHash: `0x${string}` | null
  lyraTxHash: `0x${string}` | null
  lyraOutboundMessageId?: Hash
}

export type BridgeTransaction = {
  sender: Address
  receiver: Address
  timestamp: number
  token: {
    address: Address
    symbol: string
  }
  amount: string // hex bignumber format
} & (
  | {
      type: 'deposit'
      bridgeToLyra: BridgeToLyraStatus
    }
  | {
      type: 'withdraw'
      bridgeFromLyra: BridgeFromLyraStatus
    }
  // Yield Bridge
  | {
      type: 'predeposit-yield' | 'prewithdraw-yield' | 'deposit-yield' | 'withdraw-yield'
      bridgeFromLyra: BridgeFromLyraStatus
      bridgeToLyra: BridgeToLyraStatus
      strategy: YieldStrategyId
    }
)

export type BridgeToLyraStatusPublic = Omit<BridgeToLyraStatus, 'subaccountDeposit'>

export type BridgeFromLyraStatusPublic = Omit<BridgeFromLyraStatus, 'lyraOutboundMessageId'>

export type BridgeTransactionPublic = {
  sender: Address
  receiver: Address
  timestamp: number
  token: {
    address: Address
    symbol: string
  }
  amount: string
} & (
  | {
      type: 'deposit'
      bridgeToLyra: BridgeToLyraStatusPublic
    }
  | {
      type: 'withdraw'
      bridgeFromLyra: BridgeFromLyraStatusPublic
    }
  // Yield Bridge
  | {
      type: 'predeposit-yield' | 'prewithdraw-yield' | 'deposit-yield' | 'withdraw-yield'
      bridgeFromLyra: BridgeFromLyraStatusPublic
      bridgeToLyra: BridgeToLyraStatusPublic
    }
)

export type DepositTransaction = BridgeTransaction & {
  type: 'deposit'
}

export type WithdrawTransaction = BridgeTransaction & {
  type: 'withdraw'
}

export type BridgeOptions =
  | {
      isNative: true
      isMaxApproval: boolean
    }
  | {
      isNative: false
      isSponsored: boolean
      maxFee: bigint
    }

// Eligible if no deposits in 72 hrs
export const SPONSORED_DEPOSIT_TIME_THRESHOLD_MS = SECONDS_IN_THREE_DAYS * 1000
export const SPONSORED_DEPOSIT_INTERVAL_DAYS = Math.ceil(
  SPONSORED_DEPOSIT_TIME_THRESHOLD_MS / (SECONDS_IN_DAY * 1000)
)

/**
 * Max 5 sponsored deposits within 72 hours for OP/ARB
 * AND
 * Max 1 sponsored deposit within 72 hours for Ethereum
 */
export const MAXIMUM_L1_SPONSORED_DEPOSITS = 1
export const MAXIMUM_L2_SPONSORED_DEPOSITS = 5

// Gas limit for minting tokens on Lyra Chain
// Used for socket fee estimation
export const SOCKET_BRIDGE_L2_GAS_LIMIT = 450_000
export const SOCKET_BRIDGE_YIELD_DEPOSIT_GAS_LIMIT = 600_000

// For Lyra Chain approvals, min allowance required before user deposit is flagged
export const MIN_DEPOSIT_ALLOWANCE = MAX_INT / BigInt(100_000)

export const COLLATERAL_USAGE_ROUNDING = 0.999 // 0.1%
// Dust amount to disregard when calculating withdrawable balance
export const COLLATERAL_DUST_AMOUNT = 1e-9

export const gelatoRelay = new GelatoRelay()

export type BridgeContractAddresses = {
  // Address for fast bridge on source chain
  socketDepositFastConnector: Address
  // Address for vault on source chain
  socketVault: Address
  tokenAddress: Address
  // Uses new Socket bridge implementation
  isNewBridge: boolean
} & ExternalBridgeContractAddressesConfig

export type ExternalBridgeContractAddressesConfig = {
  nativeHelper: Address
  //    // Gelato sponsored deposit helper contract on source chain
  gelatoSponsoredHelper?: Address
  // Gelato self-paying deposit helper contract on source chain
  // Note: testnet does not support self-paying
  gelatoSelfPayingHelper?: Address
}

export type SocketBridgeContractAddresses = {
  vault: Address
  fastConnector: Address
}

const mainnetNativeHelperContractAddress: Record<
  DepositNetwork,
  ExternalBridgeContractAddressesConfig
> = {
  [DepositNetwork.Ethereum]: {
    nativeHelper: '0x18a0f3F937DD0FA150d152375aE5A4E941d1527b',
    gelatoSponsoredHelper: '0xf0372da389db728a3173a7b91c5cb4437a6319ea',
    gelatoSelfPayingHelper: '0x00efac83a3168568e258ab1ec85e85c10cbaf74e',
  },
  [DepositNetwork.Optimism]: {
    nativeHelper: '0xC65005131Cfdf06622b99E8E17f72Cf694b586cC',
  },
  [DepositNetwork.Arbitrum]: {
    nativeHelper: '0x076BB6117750e80AD570D98891B68da86C203A88',
    gelatoSelfPayingHelper: '0x00eFAc83a3168568e258ab1Ec85E85C10cBAf74E',
    gelatoSponsoredHelper: '0x6FEf1bb8Ade9A836663d4c15AFd5985Fb545004f',
  },
  [DepositNetwork.Base]: {
    nativeHelper: '0x9628BBa16DB41EA7fe1FD84f9CE53bC27c63f59b',
    gelatoSelfPayingHelper: '0xd8d46a044f62d97733707176D6791b0a73cd7f6C',
    gelatoSponsoredHelper: '0x8984c20DF1DdB9344554f01d36FE753037b4A108',
  },
}

const testnetNativeHelperContractAddress: Record<
  DepositNetwork,
  ExternalBridgeContractAddressesConfig | undefined
> = {
  [DepositNetwork.Ethereum]: {
    nativeHelper: '0x46e75b6983126896227a5717f2484efb04a0c151',
    gelatoSponsoredHelper: '0xf0372da389db728a3173a7b91c5cb4437a6319ea',
  },
  [DepositNetwork.Optimism]: {
    nativeHelper: '0x3E7DEc059a3692c184BF0D0AC3d9Af7570DF6A3c',
  },
  [DepositNetwork.Arbitrum]: {
    nativeHelper: '0x5708bDE1c5e49b62cfd46D07b5cd3c898930Ef23',
    gelatoSponsoredHelper: '0xE3436F0F982fbbAf88f28DACE9b36a85c97aECdE',
  },
  [DepositNetwork.Base]: undefined,
}

export const externalDepositContractAddresses = isMainnet
  ? mainnetNativeHelperContractAddress
  : testnetNativeHelperContractAddress

export type BridgeWithdrawContractAddresses = {
  isNewBridge: boolean
  // Address for controller on Lyra Chain
  // Note: this determines destination chain and destination token for withdraws
  // e.g. for USDC vs USDC.e
  socketController: Address
  // Address for fast bridge on Lyra chain
  socketWithdrawFastConnector: Address
  withdrawHelper: Address
  lyraTokenAddress: Address
}

const bridgeMainnetWithdrawContractAddresses: Record<
  TokenId,
  { withdrawHelper: Address } | undefined
> = {
  [TokenId.USDC]: {
    withdrawHelper: '0x63972bea6f7467aedb1b68fe35330a589a992a70',
  },
  [TokenId.WBTC]: {
    withdrawHelper: '0x63972bea6f7467aedb1b68fe35330a589a992a70',
  },
  [TokenId.ETH]: {
    withdrawHelper: '0x63972bea6f7467aedb1b68fe35330a589a992a70',
  },
  [TokenId.USDT]: {
    withdrawHelper: '0x63972bea6f7467aedb1b68fe35330a589a992a70',
  },
  [TokenId.WSTETH]: {
    withdrawHelper: '0x63972bea6f7467aedb1b68fe35330a589a992a70',
  },
  [TokenId.WEETH]: {
    withdrawHelper: '0x63972bea6f7467aedb1b68fe35330a589a992a70',
  },
  [TokenId.RSWETH]: {
    withdrawHelper: '0x63972bea6f7467aedb1b68fe35330a589a992a70',
  },
  [TokenId.RSETH]: {
    withdrawHelper: '0x63972bea6f7467aedb1b68fe35330a589a992a70',
  },
  [TokenId.DAI]: {
    withdrawHelper: '0x63972bea6f7467aedb1b68fe35330a589a992a70',
  },
  [TokenId.SDAI]: {
    withdrawHelper: '0x63972bea6f7467aedb1b68fe35330a589a992a70',
  },
  [TokenId.USDE]: {
    withdrawHelper: '0x63972bea6f7467aedb1b68fe35330a589a992a70',
  },
  [TokenId.SUSDE]: {
    withdrawHelper: '0x63972bea6f7467aedb1b68fe35330a589a992a70',
  },
  [TokenId.LBTC]: {
    withdrawHelper: '0x63972bea6f7467aedb1b68fe35330a589a992a70',
  },
  [TokenId.EBTC]: {
    withdrawHelper: '0x63972bea6f7467aedb1b68fe35330a589a992a70',
  },
  [TokenId.CBBTC]: {
    withdrawHelper: '0x63972bea6f7467aedb1b68fe35330a589a992a70',
  },
}

const bridgeTestnetWithdrawContractAddresses: Record<
  TokenId,
  { withdrawHelper: Address } | undefined
> = {
  [TokenId.USDC]: {
    withdrawHelper: '0xD7080B2399B88c3520F8F793f4758D0C6ccDf48a',
  },
  [TokenId.ETH]: {
    withdrawHelper: '0xD7080B2399B88c3520F8F793f4758D0C6ccDf48a',
  },
  [TokenId.WBTC]: undefined,
  [TokenId.USDT]: {
    withdrawHelper: '0xD7080B2399B88c3520F8F793f4758D0C6ccDf48a',
  },
  [TokenId.WSTETH]: {
    withdrawHelper: '0xD7080B2399B88c3520F8F793f4758D0C6ccDf48a',
  },
  [TokenId.WEETH]: {
    withdrawHelper: '0x2805b908a0f9ca58a2b3b7900341b4ebd0b994e9',
  },
  [TokenId.RSWETH]: {
    withdrawHelper: '0x2805b908a0f9ca58a2b3b7900341b4ebd0b994e9',
  },
  [TokenId.RSETH]: {
    withdrawHelper: '0x2805b908a0f9ca58a2b3b7900341b4ebd0b994e9',
  },
  [TokenId.DAI]: {
    withdrawHelper: '0x2805b908a0f9ca58a2b3b7900341b4ebd0b994e9',
  },
  [TokenId.SDAI]: {
    withdrawHelper: '0x2805b908a0f9ca58a2b3b7900341b4ebd0b994e9',
  },
  [TokenId.USDE]: {
    withdrawHelper: '0x2805b908a0f9ca58a2b3b7900341b4ebd0b994e9',
  },
  [TokenId.SUSDE]: {
    withdrawHelper: '0x2805b908a0f9ca58a2b3b7900341b4ebd0b994e9',
  },
  [TokenId.LBTC]: {
    withdrawHelper: '0x2805b908a0f9ca58a2b3b7900341b4ebd0b994e9',
  },
  [TokenId.EBTC]: {
    withdrawHelper: '0x2805b908a0f9ca58a2b3b7900341b4ebd0b994e9',
  },
  [TokenId.CBBTC]: {
    withdrawHelper: '0x2805b908a0f9ca58a2b3b7900341b4ebd0b994e9',
  },
}

// TODO @michaelxuwu reorg withdraw helpers
export const bridgeWithdrawContractAddresses = isMainnet
  ? bridgeMainnetWithdrawContractAddresses
  : bridgeTestnetWithdrawContractAddresses

export const SOCKET_TX_API_URL = 'https://prod.dlapi.socket.tech/messages-from-tx'
export const SOCKET_MESSAGE_API_URL = 'https://prod.dlapi.socket.tech/message'

const getSocketDepositAddresses = () => {
  return isMainnet ? MAINNET_DEPOSIT_CONTRACT_ADDRESSES : TESTNET_DEPOSIT_CONTRACT_ADDRESSES
}

const getSocketWithdrawAddresses = () => {
  return isMainnet ? MAINNET_WITHDRAW_CONTRACT_ADDRESSES : TESTNET_WITHDRAW_CONTRACT_ADDRESSES
}

export const socketDepositContractAddresses = getSocketDepositAddresses()
export const socketWithdrawContractAddresses = getSocketWithdrawAddresses()

import isServer from '@lyra/core/utils/isServer'
import { DepositNetwork } from '@lyra/web/constants/chains'
import { ALCHEMY_RPC_COOKIE_NAME } from '@lyra/web/constants/cookies'
import { createPublicClient, http } from 'viem'

import { getChainForDepositNetwork } from './chains'
import { generateRpcTokenSERVER } from './server/rpc'

const getRpcCookieCLIENT = () => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${ALCHEMY_RPC_COOKIE_NAME}=`)

  if (parts.length === 2) {
    const cookieValue = parts.pop()!.split(';').shift()

    // Assuming the expiration is stored in the cookie value, e.g., as a JSON string: '{"value": "...", "expires": "..."}'
    try {
      const { value, expires } = JSON.parse(cookieValue || '{}')
      const expiryDate = new Date(expires)

      if (expiryDate > new Date()) {
        return value
      } else {
        // The cookie is expired, delete it or return null
        document.cookie = `${ALCHEMY_RPC_COOKIE_NAME}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
        return null
      }
    } catch (error) {
      // In case of parsing error (e.g., cookie is not JSON formatted)
      return cookieValue
    }
  }

  return null
}

const _refreshAccessTokenCLIENT = async (): Promise<string> => {
  const response = await fetch('/api/rpc/refresh-token', { method: 'POST' })
  if (!response.ok) {
    throw new Error('Failed to refresh rpc access token')
  }
  const json = await response.json()
  return json.token
}

let refreshAccessTokenPromise: Promise<string> | null
const refreshAccessTokenCLIENT = async (): Promise<string> => {
  if (refreshAccessTokenPromise) {
    // catch concurrent requests in memory
    return refreshAccessTokenPromise
  }
  refreshAccessTokenPromise = _refreshAccessTokenCLIENT()
  return await refreshAccessTokenPromise
}

const getAccessToken = async (): Promise<string> => {
  if (isServer) {
    const { token } = await generateRpcTokenSERVER() // no-cost to regenerate jwt
    return token
  } else {
    const existingToken = getRpcCookieCLIENT()
    if (existingToken) {
      return existingToken
    }
    return await refreshAccessTokenCLIENT()
  }
}

export const getNetworkClient = async (network: DepositNetwork) => {
  const token = await getAccessToken()
  const chain = getChainForDepositNetwork(network)

  const client = createPublicClient({
    chain: chain,
    transport: http(chain.rpcUrls.default.http[0], {
      fetchOptions: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    }),
  })

  return client
}
